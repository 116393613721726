import VueGtag from "vue-gtag";

export default defineNuxtPlugin((nuxtApp) => {
  try {
    const config = useRuntimeConfig();

    nuxtApp.vueApp.use(
      VueGtag,
      {
        config: {
          id: config.public.gaId,
        },
      },
      nuxtApp.$router as any
    );
  } catch (error) {
    console.log(error);
  }
});
