import { defineStore } from "pinia";
import { LoginInputIface, LoginResponseIface } from "../types/auth.iface";

export const useAuthStore = defineStore("auth", () => {
  const { $api } = useNuxtApp();
  const { token } = useToken();
  const { onLogin, onLogout } = useApollo();

  const loggedIn = useState("isLoggedIn", () => !!token.value);
  const loading = ref<boolean>(false);
  const logout = () => {
    loggedIn.value = false;
    token.value = null;
    onLogout("default", true);
  };
  const login = async (credentials: LoginInputIface): Promise<LoginResponseIface | undefined> => {
    try {
      const { data } = await $api.auth.login(credentials);
      token.value = data?.access_token;
      loggedIn.value = true;
      // Apollo Login purpose
      await onLogin(data?.access_token, "default", true);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  return {
    loggedIn,
    loading,
    token,
    login,
    logout,
  };
});
