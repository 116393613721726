import { FetchOptions, Headers } from "ofetch";
import AuthModule from "../repository/modules/auth";
import { ApiInstanceIface } from "../types/api.iface";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const { token } = useToken();
  const headers: HeadersInit = new Headers({});
  if (token.value) {
    headers.append("Authorization", `Bearer ${token}`);
  }
  const fetchOptions: FetchOptions = {
    baseURL: config.public.content.api.baseURL as string,
    headers,
  };

  const apiFetcher = $fetch.create(fetchOptions);

  const modules: ApiInstanceIface = {
    auth: new AuthModule(apiFetcher),
  };
  return {
    provide: {
      api: modules,
    },
  };
});
