import { createHttpLink, from } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { provideApolloClient } from "@vue/apollo-composable";
import { storeToRefs } from "pinia";

export default defineNuxtPlugin((nuxtApp) => {
  const autStore = useAuthStore();
  const { token } = storeToRefs(autStore);
  // Get Nuxt runtimeConfig and apollo instance
  const runtimeConfig = useRuntimeConfig();
  const { $apollo } = useNuxtApp();

  // Create custom links (auth, error, http...)

  // Create an authLink and set authentication token if necessary
  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token.value}`,
      },
    };
  });
  const httpLink = authLink.concat(
    createHttpLink({
      uri: runtimeConfig.public.apiGraphqlUrl,
    })
  );

  const errorLink = onError((err) => {
    nuxtApp.callHook("apollo:error", err);
  });

  // Set custom links in the apollo client (in this case, the default apollo client)
  if ($apollo) {
    ($apollo as any).defaultClient.setLink(from([errorLink, httpLink]));
    provideApolloClient(($apollo as any).defaultClient as any);
  }
  nuxtApp.hook("apollo:error", (error) => {
    const isForbiddenAccess = error.graphQLErrors
      ?.map((e) => e.message.includes("403"))
      .every((e) => e);
    if (isForbiddenAccess) {
      autStore.logout();
      nuxtApp.vueApp.$nuxt.$router.replace("/login");
    }
  });
});
