import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import api_0otAtqq586 from "/opt/atlassian/pipelines/agent/build/src/plugins/api.ts";
import apollo_ssr_YWvST7ijd1 from "/opt/atlassian/pipelines/agent/build/src/plugins/apollo-ssr.ts";
import floating_vue_IANSjr2k2Q from "/opt/atlassian/pipelines/agent/build/src/plugins/floating-vue.ts";
import gtag_client_6rnklXlFIV from "/opt/atlassian/pipelines/agent/build/src/plugins/gtag.client.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  api_0otAtqq586,
  apollo_ssr_YWvST7ijd1,
  floating_vue_IANSjr2k2Q,
  gtag_client_6rnklXlFIV
]