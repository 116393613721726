import { ApiResponseIface } from "~/types/api.iface";
import { LoginInputIface, LoginResponseIface } from "~/types/auth.iface";
import { HTTPMethod } from "~/types/enum/http.enum";
import HTTPFactory from "../factory";

class AuthModule extends HTTPFactory {
  async login(credentials: LoginInputIface): Promise<ApiResponseIface<LoginResponseIface>> {
    return await this.call(HTTPMethod.POST, "/login", credentials);
  }
}
export type { AuthModule };
export default AuthModule;
