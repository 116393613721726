import { default as indexBPvuRors8JMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/company/[id]/index.vue?macro=true";
import { default as indexw3OW1Y1JScMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/company/index.vue?macro=true";
import { default as index6ckH7doDEpMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/index.vue?macro=true";
import { default as indexExfmKKwLkBMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/index.vue?macro=true";
import { default as indexG8UL3t858cMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/login/index.vue?macro=true";
import { default as users2vIUenzRsyMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/promo/[code]/users.vue?macro=true";
import { default as addUqPaha8GMNMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/promo/add.vue?macro=true";
import { default as promoo8WbYm5JtyMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/promo.vue?macro=true";
import { default as indexBrvxt9AOA6Meta } from "/opt/atlassian/pipelines/agent/build/src/pages/users/index.vue?macro=true";
export default [
  {
    name: indexBPvuRors8JMeta?.name ?? "company-id",
    path: indexBPvuRors8JMeta?.path ?? "/company/:id()",
    meta: indexBPvuRors8JMeta || {},
    alias: indexBPvuRors8JMeta?.alias || [],
    redirect: indexBPvuRors8JMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/company/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexw3OW1Y1JScMeta?.name ?? "company",
    path: indexw3OW1Y1JScMeta?.path ?? "/company",
    meta: indexw3OW1Y1JScMeta || {},
    alias: indexw3OW1Y1JScMeta?.alias || [],
    redirect: indexw3OW1Y1JScMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index6ckH7doDEpMeta?.name ?? "dashboard",
    path: index6ckH7doDEpMeta?.path ?? "/dashboard",
    meta: index6ckH7doDEpMeta || {},
    alias: index6ckH7doDEpMeta?.alias || [],
    redirect: index6ckH7doDEpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexExfmKKwLkBMeta?.name ?? "index",
    path: indexExfmKKwLkBMeta?.path ?? "/",
    meta: indexExfmKKwLkBMeta || {},
    alias: indexExfmKKwLkBMeta?.alias || [],
    redirect: indexExfmKKwLkBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexG8UL3t858cMeta?.name ?? "login",
    path: indexG8UL3t858cMeta?.path ?? "/login",
    meta: indexG8UL3t858cMeta || {},
    alias: indexG8UL3t858cMeta?.alias || [],
    redirect: indexG8UL3t858cMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: promoo8WbYm5JtyMeta?.name ?? "promo",
    path: promoo8WbYm5JtyMeta?.path ?? "/promo",
    children: [
  {
    name: users2vIUenzRsyMeta?.name ?? "promo-code-users",
    path: users2vIUenzRsyMeta?.path ?? ":code()/users",
    meta: users2vIUenzRsyMeta || {},
    alias: users2vIUenzRsyMeta?.alias || [],
    redirect: users2vIUenzRsyMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/promo/[code]/users.vue").then(m => m.default || m)
  },
  {
    name: addUqPaha8GMNMeta?.name ?? "promo-add",
    path: addUqPaha8GMNMeta?.path ?? "add",
    meta: addUqPaha8GMNMeta || {},
    alias: addUqPaha8GMNMeta?.alias || [],
    redirect: addUqPaha8GMNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/promo/add.vue").then(m => m.default || m)
  }
],
    meta: promoo8WbYm5JtyMeta || {},
    alias: promoo8WbYm5JtyMeta?.alias || [],
    redirect: promoo8WbYm5JtyMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: indexBrvxt9AOA6Meta?.name ?? "users",
    path: indexBrvxt9AOA6Meta?.path ?? "/users",
    meta: indexBrvxt9AOA6Meta || {},
    alias: indexBrvxt9AOA6Meta?.alias || [],
    redirect: indexBrvxt9AOA6Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/users/index.vue").then(m => m.default || m)
  }
]