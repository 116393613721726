import { HTTPMethod } from "../types/enum/http.enum";

type $Fetch = typeof $fetch;

class HTTPFactory {
  private $fetch: $Fetch;
  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
  }

  async call<T>(method: HTTPMethod, url: string, data?: object | string, extras = {}): Promise<T> {
    const $res: T = await this.$fetch(url, { method, body: data, ...extras });
    return $res;
  }
}
// Export for typing purpose
export type { HTTPFactory };
// Export for class inheritance or others
export default HTTPFactory;
